// This stylesheet is intended to be imported by the consuming application and sets some global styles

:root .dark {
  --mat-select-panel-background-color: var(--colors-secondary-200);
  --mat-autocomplete-background-color: var(--colors-secondary-200);
  --mat-menu-container-color: var(--colors-secondary-200);
}

:root .light {
  --mat-select-panel-background-color: var(--colors-neutral-200);
  --mat-autocomplete-background-color: var(--colors-neutral-200);
  --mat-menu-container-color: var(--colors-secondary-200);
}

:root .light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-hover-state-background-color: var(--colors-secondary-200);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--colors-secondary-200);
  --mat-datepicker-calendar-container-background-color: var(--colors-neutral-200);
  --mat-datepicker-calendar-container-text-color: var(--colors-on-secondary-100);
}

:root .light .mat-datepicker-content.mat-primary {
  --mat-datepicker-calendar-date-hover-state-background-color: var(--colors-primary-200);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--colors-primary-200);
  --mat-datepicker-calendar-container-background-color: var(--colors-neutral-200);
  --mat-datepicker-calendar-container-text-color: var(--color-on-secondary-100);
}

:root .dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-hover-state-background-color: var(--colors-secondary-700);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--colors-secondary-700);
  --mat-datepicker-calendar-container-background-color: var(--color-secondary-200);
  --mat-datepicker-calendar-container-text-color: var(--colors-on-secondary-100);
}

:root .dark .mat-datepicker-content.mat-primary {
  --mat-datepicker-calendar-date-hover-state-background-color: var(--colors-primary-700);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--colors-primary-700);
  --mat-datepicker-calendar-container-background-color: var(--colors-secondary-200);
  --mat-datepicker-calendar-container-text-color: var(--color-on-secondary-100);
}
