@use '@angular/material' as mat;

@import 'drip-branding.variables';

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.all-component-typographies();
@include mat.core();


$app-primary: mat.define-palette(
    (
      100: var(--colors-primary-100),
      200: var(--colors-primary-200),
      300: var(--colors-primary-300),
      400: var(--colors-primary-400),
      500: var(--colors-primary-500),
      600: var(--colors-primary-600),
      700: var(--colors-primary-700),
      800: var(--colors-primary-800),
      900: var(--colors-primary-900),
      contrast: (
        50: var(--colors-on-primary-100),
        100: var(--colors-on-primary-100),
        200: var(--colors-on-primary-200),
        300: var(--colors-on-primary-300),
        400: var(--colors-on-primary-400),
        500: var(--colors-on-primary-500),
        600: var(--colors-on-primary-600),
        700: var(--colors-on-primary-700),
        800: var(--colors-on-primary-800),
        900: var(--colors-on-primary-900),
      ),
    )
);

$app-accent: mat.define-palette(
    (
      100: var(--colors-secondary-100),
      200: var(--colors-secondary-200),
      300: var(--colors-secondary-300),
      400: var(--colors-secondary-400),
      500: var(--colors-secondary-500),
      600: var(--colors-secondary-600),
      700: var(--colors-secondary-700),
      800: var(--colors-secondary-800),
      900: var(--colors-secondary-900),
      contrast: (
        100: var(--colors-on-secondary-100),
        200: var(--colors-on-secondary-100),
        300: var(--colors-on-secondary-300),
        400: var(--colors-on-secondary-400),
        500: var(--colors-on-secondary-500),
        600: var(--colors-on-secondary-600),
        700: var(--colors-on-secondary-700),
        800: var(--colors-on-secondary-800),
        900: var(--colors-on-secondary-900),
      ),
    )
);

$app-error: mat.define-palette(
    (
      100: var(--colors-error-100),
      200: var(--colors-error-200),
      300: var(--colors-error-300),
      400: var(--colors-error-400),
      500: var(--colors-error-500),
      600: var(--colors-error-600),
      700: var(--colors-error-700),
      800: var(--colors-error-800),
      900: var(--colors-error-900),
      contrast: (
        100: var(--colors-on-errors-100),
        200: var(--colors-on-errors-200),
        300: var(--colors-on-errors-300),
        400: var(--colors-on-errors-400),
        500: var(--colors-on-errors-500),
        600: var(--colors-on-errors-600),
        700: var(--colors-on-errors-700),
        800: var(--colors-on-errors-800),
        900: var(--colors-on-errors-900),
      ),
    )
);

:root {
  .light {
    $app-base-theme: mat.define-light-theme(
        (
          color: (
            primary: $app-primary,
            accent: $app-accent,
            warn: $app-error,
          ),
        )
    );
    $background: map_get($app-base-theme, background);
    $background: map_merge($background, (background: var(--colors-secondary-100)));
    $light-theme: map_merge($app-base-theme, (background: $background));

    @include mat.all-component-themes($light-theme);
  }

  .dark {
    $app-theme: mat.define-dark-theme(
        (
          color: (
            primary: $app-primary,
            accent: $app-accent,
            warn: $app-error,
          ),
        )
    );

    $background: map_get($app-theme, background);
    $background: map_merge($background, (
      background: var(--colors-secondary-100)
    ));
    $dark-theme: map_merge($app-theme, (background: $background));

    @include mat.all-component-themes($dark-theme);
  }

  --font-primary: Roboto, "Helvetica Neue", sans-serif;
}

.mdc-notched-outline__notch {
  border-right: none !important;
}

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: var(--colors-on-secondary-100);
}

app-root {
  --mat-badge-legacy-container-size: initial;
  --mat-badge-container-size: 22px;
  --mat-badge-container-padding: 0 3px;
  --mat-badge-container-shape: 8px;
}
