/*=== dark ===*/
:root {
  --list-arrow-url: url("assets/chat-selected-arrow-darktheme.png");
  --filter-invert: invert(0);

  --marker-halt: #821c49;
  --marker-halt-light: #e51b5b;
  --marker-stop: #b2bcc6;
  --marker-stop-light: #dbe3ec;
  --marker-break: #92822d;
  --marker-break-light: #b29a2a;
  --marker-event: #2A343CFF;
  --marker-event-light: #4C5A61FF;
  --map-options-bg: #0c1e34cc;
  --map-options-grey-dark: #272a30;
  --map-options-grey: #5e6774;
  --map-options-grey-transparent: #5e677399;
}
