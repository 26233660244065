:root {

  --sizes-size_2xs: var(--sizes-size_3, 4px);
  --sizes-size_xs: var(--sizes-size_4, 8px);

  --sizes-size_s: var(--sizes-size_5, 12px);
  --sizes-size_m: var(--sizes-size_6, 16px);
  --sizes-size_l: var(--sizes-size_7, 24px);

  --sizes-size_xl: var(--sizes-size_8, 32px);
  --sizes-size_2xl: var(--sizes-size_9, 48px);
  --sizes-size_3xl: var(--sizes-size_10, 56px);
  --sizes-size_4xl: var(--sizes-size_11, 64px);
  --sizes-size_5xl: var(--sizes-size-12, 72px);
  --sizes-size_6xl: var(--sizes-size_13, 96px);
  --sizes-size_7xl: var(--sizes-size_14, 128px);
  --sizes-size_8xl: var(--sizes-size_15, 192px);
  --sizes-size_9xl: var(--sizes-size_16, 256px);
  --sizes-size_10xl: var(--sizes-size_17, 384px);
  --sizes-size_11xl: var(--sizes-size_18, 512px);

  --sizes-borders-border_xs: var(--sizes-size_1, 1px);
  --sizes-borders-border_s: var(--sizes-size_2, 2px);
  --sizes-borders-border_m: var(--sizes-size_3, 4px);

  --sizes-borders-radius_xs: var(--sizes-size_3, 4px);
  --sizes-borders-radius_s: var(--sizes-size_4, 8px);
  --sizes-borders-radius_m: var(--sizes-size_5, 12px);
  --sizes-borders-radius_l: var(--sizes-size_6, 16px);
  --sizes-borders-radius_xl: var(--sizes-size_6, 26px);
  --sizes-borders-radius_full: 1000px;

  --default-font: Roboto;
  --default-header-font: Roboto Condensed;
  --fallback-font: sans-serif;

  --colors-static-black: var(--colors-black, rgba(0, 0, 0, 1));
  --colors-static-white: var(--colors-white, rgba(255, 255, 255, 1));
  --colors-brand-main: #e11a47;

  .light {
    --colors-neutral-0: var(--colors-white, rgba(255,255,255,1));
    --colors-neutral-100: var(--colors-gray-100, rgba(248,249,250,1));
    --colors-neutral-200: var(--colors-gray-200, rgba(238,243,248,1));
    --colors-neutral-300: var(--colors-gray-300, rgba(219,227,236,1));
    --colors-neutral-400: var(--colors-gray-400, rgba(196,213,229,1));
    --colors-neutral-500: var(--colors-gray-500, rgba(173,189,205,1));
    --colors-neutral-600: var(--colors-gray-600, rgba(143,154,166,1));
    --colors-neutral-700: var(--colors-gray-700, rgba(96,104,112,1));
    --colors-neutral-800: var(--colors-gray-800, rgba(68,79,91,1));
    --colors-neutral-900: var(--colors-gray-900, rgba(39,43,47,1));
    --colors-neutral-1000: var(--colors-black, rgba(0,0,0,1));

    --colors-primary-100: var(--colors-pink-100, rgba(255,232,239,1));
    --colors-primary-200: var(--colors-pink-200, rgba(250,188,208,1));
    --colors-primary-300: var(--colors-pink-300, rgba(240,120,158,1));
    --colors-primary-400: var(--colors-pink-400, rgba(223,29,90,1));
    --colors-primary-500: var(--colors-pink-500, rgba(201,6,67,1));
    --colors-primary-600: var(--colors-pink-600, rgba(184,9,64,1));
    --colors-primary-700: var(--colors-pink-700, rgba(158,12,58,1));
    --colors-primary-800: var(--colors-pink-800, rgba(126,6,44,1));
    --colors-primary-900: var(--colors-pink-900, rgba(91,3,30,1));

    --colors-secondary-100: var(--colors-blue-100, rgba(255, 255, 255, 1));
    --colors-secondary-200: var(--colors-blue-200, rgba(236, 236, 236, 1));
    --colors-secondary-300: var(--colors-blue-300, rgba(187, 187, 187, 1));
    --colors-secondary-400: var(--colors-blue-400, rgba(140, 140, 140));
    --colors-secondary-500: var(--colors-blue-500, rgba(27,59,101,1));
    --colors-secondary-600: var(--colors-blue-600, rgba(30,64,109,1));
    --colors-secondary-700: var(--colors-blue-700, rgba(19,49,89,1));
    --colors-secondary-800: var(--colors-blue-800, rgba(15,46,79,1));
    --colors-secondary-900: var(--colors-blue-900, rgba(12,30,52,1));

    --colors-warning-100: #FFF6E5;
    --colors-warning-200: #ffdb99;
    --colors-warning-300: #ffc04d;
    --colors-warning-400: #ffae1a;
    --colors-warning-500: #ffa500;
    --colors-warning-600: #cc8400;
    --colors-warning-700: #b37300;
    --colors-warning-800: #996300;
    --colors-warning-900: #664200;

    --colors-error-100: #FCB5B5;
    --colors-error-200: #f09f9f;
    --colors-error-300: #e86e6e;
    --colors-error-400: #e13e3e;
    --colors-error-500: #d90e0e;
    --colors-error-600: #ae0b0b;
    --colors-error-700: #820808;
    --colors-error-800: #570606;
    --colors-error-900: #2b0303;

    --colors-success-100: #EEFFFD;
    --colors-success-200: #81cfc6;
    --colors-success-300: #4ebbaf;
    --colors-success-400: #1ba898;
    --colors-success-500: #029e8d;
    --colors-success-600: #028e7f;
    --colors-success-700: #027e71;
    --colors-success-800: #016f63;
    --colors-success-900: #014f47;

    --colors-on-primary-100: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-200: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-300: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-800: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-900: var(--colors-white, rgba(255,255,255,1));

    --colors-on-secondary-100: var(--colors-secondary-900, rgba(12, 30, 52, 1));
    --colors-on-secondary-200: var(--colors-secondary-900, rgba(12, 30, 52, 1));
    --colors-on-secondary-300: var(--colors-secondary-900, rgba(255, 255, 255, 1));
    --colors-on-secondary-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-secondary-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-secondary-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-secondary-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-secondary-800: var(--colors-secondary-100, rgba(255, 255, 255, 1));
    --colors-on-secondary-900: var(--colors-secondary-100, rgba(255, 255, 255, 1));

    --colors-on-success-100: var(--colors-success-500);
    --colors-on-success-200: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-300: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-800: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-900: var(--colors-white, rgba(255,255,255,1));

    --colors-on-warning-100: var(--colors-warning-500);
    --colors-on-warning-200: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-300: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-800: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-900: var(--colors-white, rgba(255,255,255,1));

    --colors-on-error-100: var(--colors-red-500, rgba(217,14,14,1));
    --colors-on-error-200: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-300: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-800: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-900: var(--colors-white, rgba(255,255,255,1));
  }

  .dark {
    --colors-neutral-0: var(--colors-static-white, rgba(255,255,255,1));
    --colors-neutral-100: var(--colors-gray-100, rgba(248,249,250,1));
    --colors-neutral-200: var(--colors-gray-200, rgba(238,243,248,1));
    --colors-neutral-300: var(--colors-gray-300, rgba(219,227,236,1));
    --colors-neutral-400: var(--colors-gray-400, rgba(196,213,229,1));
    --colors-neutral-500: var(--colors-gray-500, rgba(173,189,205,1));
    --colors-neutral-600: var(--colors-gray-600, rgba(143,154,166,1));
    --colors-neutral-700: var(--colors-gray-700, rgba(96,104,112,1));
    --colors-neutral-800: var(--colors-gray-800, rgba(68,79,91,1));
    --colors-neutral-900: var(--colors-gray-900, rgba(39,43,47,1));
    --colors-neutral-1000: var(--colors-static-black, rgba(0,0,0,1));

    --colors-primary-100: var(--colors-pink-900, rgba(91,3,30,1));
    --colors-primary-200: var(--colors-pink-800, rgba(126,6,44,1));
    --colors-primary-300: var(--colors-pink-700, rgba(158,12,58,1));
    --colors-primary-400: var(--colors-pink-600, rgba(184,9,64,1));
    --colors-primary-500: var(--colors-pink-500, rgba(201,6,67,1));
    --colors-primary-600: var(--colors-pink-400, rgba(223,29,90,1));
    --colors-primary-700: var(--colors-pink-300, rgba(240,120,158,1));
    --colors-primary-800: var(--colors-pink-200, rgba(250,188,208,1));
    --colors-primary-900: var(--colors-pink-100, rgba(255,232,239,1));

    --colors-secondary-100: var(--colors-blue-900, rgba(12,30,52,1));
    --colors-secondary-200: var(--colors-blue-800, rgba(15,46,79,1));
    --colors-secondary-300: var(--colors-blue-700, rgba(19,49,89,1));
    --colors-secondary-400: var(--colors-blue-600, rgba(30,64,109,1));
    --colors-secondary-500: var(--colors-blue-500, rgba(27,59,101,1));
    --colors-secondary-600: var(--colors-blue-400, rgba(59,99,178,1));
    --colors-secondary-700: var(--colors-blue-300, rgba(109,148,226,1));
    --colors-secondary-800: var(--colors-blue-200, rgba(144,178,246,1));
    --colors-secondary-900: var(--colors-blue-100, rgba(211,224,249,1));

    --colors-warning-100: #FFF6E5;
    --colors-warning-200: #ffdb99;
    --colors-warning-300: #ffc04d;
    --colors-warning-400: #ffae1a;
    --colors-warning-500: #ffa500;
    --colors-warning-600: #cc8400;
    --colors-warning-700: #b37300;
    --colors-warning-800: #996300;
    --colors-warning-900: #664200;

    --colors-error-100: #FCB5B5;
    --colors-error-200: #f09f9f;
    --colors-error-300: #e86e6e;
    --colors-error-400: #e13e3e;
    --colors-error-500: #d90e0e;
    --colors-error-600: #ae0b0b;
    --colors-error-700: #820808;
    --colors-error-800: #570606;
    --colors-error-900: #2b0303;

    --colors-success-100: #EEFFFD;
    --colors-success-200: #81cfc6;
    --colors-success-300: #4ebbaf;
    --colors-success-400: #1ba898;
    --colors-success-500: #029e8d;
    --colors-success-600: #028e7f;
    --colors-success-700: #027e71;
    --colors-success-800: #016f63;
    --colors-success-900: #014f47;

    --colors-on-primary-100: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-200: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-300: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-800: var(--colors-white, rgba(255,255,255,1));
    --colors-on-primary-900: var(--colors-white, rgba(255,255,255,1));

    --colors-on-secondary-100: var(--colors-secondary-900, rgba(255, 255, 255, 1));
    --colors-on-secondary-200: var(--colors-secondary-900, rgba(255, 255, 255, 1));
    --colors-on-secondary-300: var(--colors-secondary-900, rgba(255, 255, 255, 1));
    --colors-on-secondary-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-secondary-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-secondary-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-secondary-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-secondary-800: var(--colors-secondary-100, rgba(255, 255, 255, 1));
    --colors-on-secondary-900: var(--colors-secondary-100, rgba(255, 255, 255, 1));

    --colors-on-success-100: var(--colors-success-500);
    --colors-on-success-200: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-300: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-800: var(--colors-white, rgba(255,255,255,1));
    --colors-on-success-900: var(--colors-white, rgba(255,255,255,1));

    --colors-on-warning-100: var(--colors-warning-500);
    --colors-on-warning-200: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-300: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-800: var(--colors-white, rgba(255,255,255,1));
    --colors-on-warning-900: var(--colors-white, rgba(255,255,255,1));

    --colors-on-error-100: var(--colors-error-500);
    --colors-on-error-200: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-300: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-400: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-500: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-600: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-700: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-800: var(--colors-white, rgba(255,255,255,1));
    --colors-on-error-900: var(--colors-white, rgba(255,255,255,1));
  }
}

.h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 80px;
  font-weight: bold;
  line-height: 88px;
  letter-spacing: -2px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
}

.h2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  letter-spacing: -1px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
}

.h3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
}

.h4 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: -0.25px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
}

.h5 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
}

.h6 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.25px;
  text-decoration: none;
  text-transform: none;
  margin: 0;
}

.body1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: uppercase;
}

.body1Bold {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.body1Regular {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.body1Underlined {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0px;
  text-decoration: underline;
  text-transform: none;
}

.body2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: uppercase;
}

.body2Bold {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.body2Regular {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.body2Underlined {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: underline;
  text-transform: none;
}

.body3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: uppercase;
}

.body3Bold {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.body3Regular {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.body3Underlined {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0px;
  text-decoration: underline;
  text-transform: none;
}

.body4 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: uppercase;
}

.body4Bold {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.body4Regular {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

.body4Underlined {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0px;
  text-decoration: underline;
  text-transform: none;
}

.text-900 {
  color: var(--colors-secondary-900);
}
