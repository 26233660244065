/* ===== ON DARK MODE (DEFAULT) ===== */
.dp-marker {
  width: 70px;
  height: 60px;
  z-index: 0;

  &.clustered {
    opacity: 0 !important;
  }

  &.offline {
    opacity: 0.5;
  }

  &:hover,
  &.hovered {
    z-index: 3;
    cursor: pointer;
    --hoverdistance: 55px;

    opacity: 1;

    .cover:is(.has-trip) {
      opacity: 0;
    }
    .type {
      transform: translateY(calc(var(--hoverdistance) * -1)) !important;
    }
    .trip {
      transform: translateY(calc(var(--hoverdistance) * 0)) !important;
    }
    .trailer {
      transform: translateY(calc(var(--hoverdistance) * 1)) !important;
    }
  }

  .dp-inner {
    position: relative;
    width: 100%;
    height: 100%;

    .dp-layer {
      --distance: 2px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;

      &.cover {
        background-image: url("../assets/marker-remake/speed-layer.png");
        z-index: 4;
        transform: translateY(calc(var(--distance) * -2));

        div {
          transform: translateY(5px);

          .cover-value {
            font-size: 2.6rem;
            margin-top: 3px;
            color: white;
          }
          .cover-unit {
            // margin-right: -8px;
          }
        }
      }

      &.type {
        transform: translateY(calc(var(--distance) * -1));
        z-index: 3;
      }
      &.truck {
        background-image: url("../assets/marker-remake/asset-layer-truck.png");
      }
      &.car {
        background-image: url("../assets/marker-remake/asset-layer-car.png");
      }
      &.tank {
        background-image: url("../assets/marker-remake/asset-layer-tank.png");
      }
      &.trip {
        background-image: url("../assets/marker-remake/trip-layer.png");
        transform: translateY(calc(var(--distance) * 1));
        margin-top: -2px;
        z-index: 2;
      }
      &.trailer {
        background-image: url("../assets/marker-remake/trailer-layer.png");
        transform: translateY(calc(var(--distance) * 2));
        margin-top: -2px;
        z-index: 1;
      }
    }

    .dp-rose {
      position: absolute;
      top: -26px;
      left: -26px;
      right: -26px;
      bottom: -26px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("../assets/marker-remake/rose-layer-inverted.png");
      transform: rotateX(35deg) translateY(6px) rotateZ(0deg);
      transition: transform 1;
    }

    .dp-meta {
      position: absolute;
      color: black;
      white-space: nowrap;
      pointer-events: none;

      &.right {
        left: 60px;
        top: 10px;
        border-top: 1px solid white;
        padding-left: 35px;
        height: 50px;
        color: white;
        overflow: visible;

        .gps-datetime {
          font-size: 0.9rem;
          transform: translateY(-31px);
        }
        .asset-name {
          font-size: 1.2rem;
          transform: translateY(-26px);
        }

        .owner-name {
          font-size: 1.2rem;
          transform: translateY(-24px);
        }
      }

      &.left {
        right: 36px;
        top: 48px;
        border-bottom: 1px solid white;
        padding-right: 50px;
        color: white;
        overflow: visible;
        font-size: 0.9rem;
      }
    }
  }
}

/* ===== ON LIGHT MODE ===== */
body[map-color-mode="light"] {
  .dp-rose {
    background-image: url("../assets/marker-remake/rose-layer-inverted.png") !important;
  }

  .asset-name,
  .owner-name,
  .location,
  .postal-code,
  .gps-datetime {
    background: #334970;
    padding: 4px 4px 4px 4px;
    color: white;
  }

  .left,
  .right {
    border-color: #334970 !important;
  }

  .gps-datetime {
    transform: translateY(-29px) !important;
  }
  .asset-name {
    transform: translateY(-21px) !important;
  }

  .owner-name {
    transform: translateY(-21px) !important;
  }
}

/* preload asset's marker layer */
// body::after {
//   position: absolute;
//   width: 0;
//   height: 0;
//   overflow: hidden;
//   z-index: -1; // hide images
//   content: url("../assets/marker-remake/speed-layer.png"); // load images
// }

body[map-color-mode="satelite"] {
  .asset-name,
  .owner-name,
  .location,
  .postal-code,
  .gps-datetime {
    background: #334970;
    padding: 4px 4px 4px 4px;
    color: white;
  }
}
