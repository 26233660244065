@import "_variables.scss";
@import "map_popup.scss";

/*@import "@angular/material/prebuilt-themes/indigo-pink.css";*/

@import "theme.scss";
@import "mobile-queries.scss";
// @import "src/chatvisor.css";

@import "./style/dp-marker.scss";

// ========== MISC ==========


app-root {
  --mat-badge-legacy-container-size: initial;
  --mat-badge-container-size: 22px;
  --mat-badge-container-padding: 0 3px;
  --mat-badge-container-shape: 8px;
}

.lowres * {
  transition: none !important;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

h1 {
  font-family: var(--header-font);
  font-size: 1.8em;
  color: var(--colors-on-secondary-100);
  position: relative;
  text-transform: uppercase;
}
h1::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: var(--colors-primary-500);
}

.mdc-list-item__primary-text {
  color: var(--colors-on-secondary-100) !important;
}

.mdc-menu-surface {
  background-color: var(--colors-secondary-200) !important;
    border-radius: 10px !important;
}

body {
  -webkit-tap-highlight-color: transparent;
  /*-webkit-user-select: none; */ /* enabled text selection (https://d3eif.atlassian.net/browse/DRIP-301)  */
  -webkit-touch-callout: none;
  scrollbar-width: none !important;
  overflow: hidden;
}

body {
  margin: 0 !important;
  --mdc-snackbar-container-color: none !important;
  --mdc-dialog-container-color: transparent;
}

browser {
  margin-bottom: -17px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
::-webkit-scrollbar {
  display: none;
}

// hide scroll for firefox (DRIP-878),
// class 'firefox' is added in AppComponent's initBrowserEngineClass method
body.firefox * {
  scrollbar-width: none;
}

.selectable {
  -webkit-touch-callout: auto; /* iOS Safari */
  -webkit-user-select: auto; /* Safari */
  -khtml-user-select: auto; /* Konqueror HTML */
  -moz-user-select: auto; /* Firefox */
  -ms-user-select: auto; /* Internet Explorer/Edge */
  user-select: auto; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

input:focus,
button:focus,
div:focus {
  outline-style: none;
  box-shadow: none;
}

body,
html {
  background: var(--colors-secondary-100);
  color: var(--colors-on-secondary-100);
  overflow: auto;
  font-family: var(--default-font), var(--fallback-font);
  --header-font: var(--default-header-font), var(--fallback-font);
}

a {
  text-decoration: none;
  color: inherit;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
}

.testmarker {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: blue;
}

// ========== MARKER ALT ==========

.route-marker-popup {
  transform: translate(97px, 13px);
  background-color: var(--colors-on-secondary-100);
  color: black;
  padding: 6px 10px;
  margin-right: 0px;
  border-radius: 5px;
}

.tempmarker {
  width: 40px;
  height: 53px;
  background: url("./assets/marker/tempmarker.png") no-repeat center bottom;
  background-size: contain;
}

.history-time-popup {
  padding: 6px 10px;
  background-color: var(--colors-static-white);
  border-radius: 5px;
  color: var(--colors-static-black);
}

.popup-temp-chart g path {
  fill: transparent;
}

.popup-temp-chart svg g g path {
  stroke: var(--colors-on-secondary-100);
  stroke-width: 1.5;
}

/* TASK SPINNER ANIMATION*/
@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-spinner {
  -webkit-animation: spinning 5s infinite; /* Safari 4+ */
  -moz-animation: spinning 5s infinite; /* Fx 5+ */
  -o-animation: spinning 5s infinite; /* Opera 12+ */
  animation: spinning 5s infinite; /* IE 10+, Fx 29+ */
  animation-timing-function: linear;
  transform-origin: 46% 50%;
}

.stopmarker {
  --markerimage-size: 40px;
  display: flex;
  z-index: 2;
  line-height: 1;
  .markerimage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: var(--markerimage-size);
    height: var(--markerimage-size);
    margin-left: calc(var(--markerimage-size) / 2 * -1);
    .markerimage {
      width: 100%;
      height: 60%;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
    }
  }
  .label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    gap: 2px;
    text-transform: uppercase;

    b {
      font-size: 1.1em;
    }
  }
  &::after {
    content: "";
    width: var(--markerimage-size);
    height: var(--markerimage-size);
    border-radius: 0 100% 100% 0;
    position: absolute;
    right: calc(var(--markerimage-size) / 2 * -1);
    z-index: -1;
  }

  .extra-circle {
    position: absolute;
    right: -55px;
    top: 50%;
    transform: translateY(-50%);
    background-color: black;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
  }
}

.stopmarker:hover {
  z-index: 99999;
}

.stopmarker.stop {
  color: black;
  background-color: var(--marker-stop);
  .markerimage-container {
    background-color: var(--marker-stop-light);
    .markerimage {
      background-color: black;
      -webkit-mask-image: url("./assets/marker/marker-stop.svg");
      mask-image: url("./assets/marker/marker-stop.svg");
    }
  }
  &::after {
    background-color: var(--marker-stop);
  }
}

.stopmarker-pdf.stop {
  color: black;
  background-color: var(--marker-stop);
  .markerimage-container {
    background-color: var(--marker-stop-light);
    .markerimage {
      background-color: black;
    }
  }
  &::after {
    background-color: var(--marker-stop);
  }
}

.stopmarker.via {
  color: black;
  background-color: var(--marker-stop);
  .markerimage-container {
    background-color: var(--marker-stop-light);
    .markerimage {
      background-color: black;
      -webkit-mask-image: url("./assets/marker/directions-light.svg");
      mask-image: url("./assets/marker/directions-light.svg");
    }
  }
  &::after {
    background-color: var(--marker-stop);
  }
}

.stopmarker.break {
  color: white;
  background-color: var(--marker-break);
  .markerimage-container {
    background-color: var(--marker-break-light);
    .markerimage {
      background-color: white;
      -webkit-mask-image: url("./assets/marker/marker-break.svg");
      mask-image: url("./assets/marker/marker-break.svg");
    }
  }
  &::after {
    background-color: var(--marker-break);
  }
}

.stopmarker.halt {
  color: white;
  background-color: var(--marker-halt);
  .markerimage-container {
    background-color: var(--marker-halt-light);
    .markerimage {
      background-color: white;
      -webkit-mask-image: url("./assets/marker/marker-halt.svg");
      mask-image: url("./assets/marker/marker-halt.svg");
    }
  }

  &::after {
    background-color: var(--marker-halt);
  }
}

.stopmarker.event {
  color: white;
  background-color: var(--marker-event-light);

  .markerimage-container {
    background-color: var(--marker-event);

    .markerimage {
      background-color: white;
      -webkit-mask-image: url("./assets/marker/marker-alert.svg");
      mask-image: url("./assets/marker/marker-alert.svg");
    }
  }
  &::after {
    background-color: var(--marker-event-light);
  }

  .extra-circle {
    background-color: var(--marker-event-light);
  }
}

.stopmarker.event-image {
  color: white;
  background-color: var(--marker-event-light);

  .markerimage-container {
    background-color: var(--marker-event);

    .markerimage {
      background-color: white;
      -webkit-mask-image: url("./assets/marker/marker-event.svg");
      mask-image: url("./assets/marker/marker-event.svg");
    }
  }

  &::after {
    background-color: var(--marker-event-light);
  }

  .extra-circle {
    background-color: var(--marker-event-light);
  }
}

.clustermarker {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 1px;
}
.clustermarker .content {
  width: 80%;
  height: 80%;
  margin: 10%;
  background-color: var(--colors-neutral-0);
  border-radius: 100px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}
.clustermarker .label {
  color: black;
}
.clustermarker .count {
  color: black;
  font-family: var(--header-font);
  font-size: 2.7em;
  padding-top: 29%;
}

.minimal-marker-description {
  background-color: var(--colors-on-secondary-100);
  border-radius: 5px;
  color: black;
  padding: 10px 15px;
}

// padding and margin classes
$paddingAndMarginScaleSize: 42;
$i: 2;

@while $i < $paddingAndMarginScaleSize {
  .p-#{$i} {
    padding: #{$i}px !important;
  }

  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }

  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }

  .m-#{$i} {
    margin: #{$i}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }

  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  $i: $i + 2;
}

/*
 * All of our labels has some odd behavior (applied from God knows whom and where)
 * which forces their position to be bellow middle and to
 */
::ng-deep {
  mat-form-field.align-label.mat-focused
    > div.mat-form-field-wrapper
    > div.mat-form-field-flex
    > div.mat-form-field-infix
    > span.mat-form-field-label-wrapper
    > label.mat-form-field-empty {
    margin-top: -4px !important;
  }
  mat-form-field.align-label
    > div.mat-form-field-wrapper
    > div.mat-form-field-flex
    > div.mat-form-field-infix
    > span.mat-form-field-label-wrapper
    > label.mat-form-field-empty {
    margin-top: -8px !important;
  }

  mat-error {
    color: var(--colors-error-500);
    margin-top: 10px;
  }

  .mat-form-field-required-marker {
    color: var(--colors-error-500) !important;
    font-weight: bold;
  }

  mat-error[server-validation]:first-letter {
    text-transform: capitalize;
  }
}

.mat-form-field-label {
  /*change color of label*/
  color: var(--colors-on-secondary-100) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: transparent !important;
}

.mat-mdc-outlined-button {
  border-color: #ffffff1f;
}

.mat-mdc-outlined-button:disabled {
  color: gray;
  border-color: #ffffff1f;
}

.input-form-height {
  float: right;
  width: 200px;
  margin-right: 5%;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}

// INPUT DIMENSIONS

.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

.mat-mdc-select {
  display: flex !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  color: var(--colors-on-secondary-100) !important;
  padding: 24px 24px 0px 24px;
}

.mat-mdc-checkbox .mdc-form-field {
  color: var(--colors-on-secondary-100);
}

.mdc-checkbox__background {
  border-color: var(--colors-on-secondary-100) !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  background-color: #029e8d !important;
}

// CALENDAR
.mat-calendar-arrow {
  fill: var(--colors-on-secondary-100);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  color: var(--colors-on-secondary-100);
}
.mat-calendar-table-header th {
  color: var(--colors-on-secondary-100);
}

.mat-calendar-body-label {
  color: var(--colors-on-secondary-100);
}

.mat-calendar-body-cell-content {
  color: var(--colors-on-secondary-100);
}

.mat-calendar-body-today {
  border-color: var(--colors-on-secondary-100) !important;
}

.mat-calendar-table-header-divider::after {
  background-color: var(--colors-on-secondary-100) !important;
  opacity: 0.12;
}
.mdc-button {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.textarea {
  ::ng-deep.mat-mdc-text-field-wrapper {
    height: unset !important;
    padding-right: 4px !important;
  }
  ::ng-deep.mat-mdc-form-field-infix {
    min-height: 56px !important;
    display: block !important;
    padding: 16px 0px !important;
  }
}

.border-radius-popup {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 20px !important;
  }
}

.drip-card-full-height {
  .drip-card {
    height: 100%;
  }
}

.popup-route-overlay {
  z-index: 3 !important;
}

.mdc-snackbar__label,
.mdc-snackbar__surface {
  padding: 0 !important;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.roboto-condensed {
  font-family: var(--header-font);
  font-weight: 800;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.title-big-condensed {
  font-size: 18px;
  font-family: var(--header-font);
  font-weight: 700;
}

.title-medium-condensed {
  font-size: 14px;
  font-family: var(--header-font);
  font-weight: 700;
}

app-arrival-monitor-tasks-list{
  height: 100%;
}

.new-line-tooltip {
  white-space: pre-line !important;
}


.marker-status-pdf {
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 37px;
  width: 37px;
}

.default-marker-pdf {
  height: auto;
  width: auto;
  background-image: none;
  background-size: auto;
  background-color: white;
}

.time-container {
  color: var(--colors-on-secondary-100);
}

.time-container::before {
  background-color: var(--colors-on-secondary-100) !important;
  opacity: 0.12 !important;
}

.drip-scrollbar {
  overflow: auto;
}

.drip-scrollbar::-webkit-scrollbar {
  display: block;
  border-radius: 10px;
  width: 4px;
  height: 4px;
  background: var(--colors-secondary-100);
}

.drip-scrollbar::-webkit-scrollbar-thumb {
  background: var(--colors-primary-500);
  border-radius: 10px;
}

.drip-scrollbar::-webkit-scrollbar-corner {
  display: none;
}

.mdc-notched-outline__notch {
  border-left: none !important;
}

.text-primary-highlight {
  color: var(--colors-primary-500);
  font-weight: 700;
}

.clickable {
  cursor: pointer;
}
