body {
  --bottom-menu-height: 72px;
  --header-height: 45px;

  position: fixed;
}

@media only screen and (max-width: 768px) {
  .window {
    left: 0px !important;
    top: 0 !important;
    right: 0px !important;
    bottom: 72px !important;
  }

  .login-wrapper-main {
    width: 100% !important;
  }

  .custom-controls-wrapper {
    transform: translateY(-15px);
  }

  #chat-wrapper {
    width: 100%;
    height: calc(100% - var(--bottom-menu-height));
    top: var(--header-height);
    right: 0px;
    left: 0px;
    border: none;
    border-radius: 0px;

    #send-wrapper {
      position: fixed !important;
      bottom: 60px !important;
    }
  }

  .map-wrapper {
    bottom: var(--bottom-menu-height) !important;
    top: var(--header-height) !important;
  }

  .asset-row {
    width: 210vw;
  }

  .custom-tabbar-wrapper .tabbar-item {
    min-width: 0px !important;
    padding: 4px !important;
  }

  .filter-row {
    display: block !important;
    text-align: center;
    padding-top: 10px;
  }
}
