$background-color: #002a38; /*#00222e;*/
$sidebarWidth: 80px;
$headerbarHeight: 60px;
$headerbarBorder: 2px;
$blue-light: #0b1826;
$blue-mid: #1e242d;
$blue-mid-transparent: rgba(186, 214, 255, 0.1);
$d3eif-pink: var(--colors-primary-500);

$borderColor: #202936;

$shipmentBackground: var(--colors-secondary-200);
$shipmentItemBackground: #0e212a;
$customItemBackground: var(--colors-secondary-300);

// Chat variables
$chatHeight: 85vh;
$chatHeaderProfileHeight: 166px;
$chatSenderContainerHeight: 60px;
$chatSearchContainer: 150px;
$chatImgProfileLarge: 60px;
$chatImgProfileMid: 40px;
$chatImgProfileSmall: 36px;
